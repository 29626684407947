import React from 'react'

import SvgGoogle from '@svg/google.inline'
import SvgConnectUsers from '@svg/connectUsers.inline'
import SvgLeap from '@svg/leap.inline'
import SvgSpeech from '@svg/speech.inline'
import SvgStarSpeech from '@svg/starSpeech.inline'

export const pageData = {
  title: '10 Best Real Estate Marketing Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '10 Best Real Estate Marketing Websites',
      url: '/10-best-real-estate-marketing-websites/',
    },
  ],
  uri: '/10-best-real-estate-marketing-websites/',
  buttonsHeight: { desktop: 1990, tablet: 1280, phone: 2880 },
  lcf: {
    version: 'LCF08',
    modelOrBackground: 'CutOutCassie',
    title: 'Need help with your website?',
    subtitle: 'Let us do the Design and SEO work you need to succeed!',
    submitLabel: 'Schedule a consultation',
    leadName: 'Lead Contact Form',
    layout: 'B',
  },
}

export const bannerData = {
  layout: { container: 'product-gallery' },
  imgAlt: 'The Caroll Group laptop',
  heading: {
    text: '10 Best Real Estate Marketing Websites',
    className: 'heading-2',
    tabletClassName: 'heading-3',
  },
  subheading: {
    text: 'Agent and Broker Websites that Get Top Results',
    className: 'subtitle-7',
  },
  bg: { width: 1564, height: 752 },
  form: {
    layout: 'default',
    titleClassName: 'subtitle-2',
    subheadingClassName: 'subtitle-6',
    buttonClassName: 'primary-button large',
    buttonTabletClassName: 'primary-button small',
  },
}

export const introData = {
  heading: {
    text: 'Does Your Website Work as Hard as You?',
    className: 'subtitle-2',
  },
  paragraphs: {
    text: [
      'Your real estate website is more than just a place to display listings — it’s an integral part of your real estate marketing strategy.',
      'A strong website builds your personal brand, helps you build an email database, encourages prospective buyers to make that first contact, and clarifies the home-buying process.',
      'Agent Image specializes in creating powerful, immersive websites for real estate agents that deliver exceptional business results.',
    ],
    className: 'default-body large',
  },
  button: {
    text: 'View our case studies',
    scrollTo: '#websitelist-marketing-websites',
  },
}

export const websiteListData = {
  heading: {
    text: 'Real Estate Marketing',
    className: 'heading-5',
    phoneClassName: 'heading-3',
  },

  subheading: {
    text: 'Websites That Elevate ',
    className: 'subtitle-1',
  },
  paragraph: {
    text1:
      'Each of the real estate professionals discussed below turned to Agent Image to create a professional website that would be at the center of their marketing efforts.',
    text2:
      'And the best part? Our website designers can give your real estate business an equally impressive and effective site, too.',
    className: 'default-body large',
  },
  buttonText: 'Get pricing & features',
  websiteList: {
    titleClassName: 'heading-3',
    subtitleClassName: 'subheading-7',
    paragraphClassName: 'default-body large',
    labelClassName: 'small-heading strong',
    websiteItem: [
      {
        agent: 'Natalie Gregory',
        location: 'Decatur, GA',
        description:
          'NatalieGregorySold.com is an example of a real estate website with great content presentation. Navigation is user-friendly and site visitors will surely enjoy the clutter-free look of the community and property pages.',
        challenges: [
          'Natalie Gregory and her team are right at the center of the competitive Atlanta real estate market.',
          'The challenge was to jump-start search engine visibility for their newly redesigned website.',
        ],
        solutions: [
          'To maximize their online presence, they tapped Agent Image to improve the SEO score and rankings of nataliegregorysold.com.',
          'The focus is on monthly content creation and on-page optimization.',
        ],
        results: [
          'The project is still in progress but the site is already progressing in terms of Google search exposure for keywords like: “Decatur realtors”, “Decatur real estate agents”, “Decatur GA real estate, “Top Atlanta real estate agents” and more.',
        ],
        highlights: {
          items: [
            {
              label: 'Search Exposure for Google Keywords',
              icon: <SvgGoogle />,
            },
          ],
        },
      },
      {
        agent: 'The Oppenheim Group',
        location: 'Los Angeles, CA',
        description:
          'Befitting their status as realtors to the stars, the Oppenheim Group’s website design exudes opulence and class. It’s bold, dynamic, easy to navigate, and highlights the best property listings in the market.',
        challenges: [
          'The Oppenheim Group wanted to improve website traffic and strengthen its hold on the market. To do this, they have decided to populate their website with high-quality content that’s relevant to their target market.',
        ],
        solutions: [
          'Agent Image conducted extensive market and competitor research, including an SEO anatomy of top-ranking content. This led to the creation of hyper-targeted long-form content that has been optimized with intent-specific keyword',
        ],
        results: [
          'After implementation, The Oppenheim Group reported a 5,000% leap in user traffic and a spot on Google’s Local 3-pack.',
          'This can be attributed to the new long-form SEO content. With 990 ranking keywords by the end of the project, these pages improved OGroup.com’s search visibility.',
        ],
        highlights: {
          items: [
            { label: '5,000%  leap in user traffic', icon: <SvgLeap /> },
            { label: '990 ranking keywords', icon: <SvgSpeech /> },
          ],
        },
      },
      {
        agent: 'The Chad Carroll Group',
        location: 'South Florida',
        description:
          'The Chad Carroll Group’s homepage is a beautiful homage to the environs of South Florida and its vibrant communities. Featuring a jewel-blue and white palette, it draws visitors in and encourages them to view the firm’s impressive selection of luxurious high-end homes.',
        challenges: [
          'Two of the main points highlighted by The Chad Carroll Group were their desire to shore their online presence and improve brand awareness among potential home buyers. To achieve this, the Group opted to implement a steady stream of high-quality blogs on their website.',
        ],
        solutions: [
          'Agent Image tackled the challenge by analyzing current web traffic. Engaging and well-written blog posts that are optimized for high-traffic, hyper-targeted, user-intent keywords were created. Existing key pages were also optimized.',
        ],
        results: [
          'This includes a 100% increase in TheChadCarrollGroup.com’s site traffic and a reduction in its bounce rate. The site’s online visibility vastly improved as well, with the site earning Page 1 rankings for over 121 keywords.',
        ],
        highlights: {
          items: [
            { label: '100% Increase in Website Traffic', icon: <SvgLeap /> },
            { label: 'Page 1 Ranking Over 121 Keywords', icon: <SvgSpeech /> },
          ],
          text:
            'Significant improvements were noted three months into the project’s implementation.',
        },
      },
      {
        agent: 'Valery Neuman',
        location: 'Coachella Valley, CA',
        description:
          'Befitting their status as realtors to the stars, the Oppenheim Group’s website design exudes opulence and class. It’s bold, dynamic, easy to navigate, and highlights the best property listings in the market.',
        challenges: [
          'One of Southern California’s leading real estate experts, Valery Neuman is known for her deep knowledge of Coachella Valley’s luxury real estate market and national trends. Looking very much like a magazine, ValeryNeuman.com has an air of understated sophistication and is incredibly easy to navigate.',
        ],
        solutions: [
          'After extensive keyword research and behavior analysis, Agent Image created and published well-written community pages, fully optimized with targeted keywords. This was accompanied by technical on-page optimization to boost the site’s overall performance.',
        ],
        results: [
          'As a result of Agent Image’s efforts, ValeryNeyman.com nabbed a higher search position with 212 ranking organic keywords.',
          'Other notable improvements include a 30% growth in the site’s users and longer time spent on the site’s community pages.',
        ],
        highlights: {
          items: [
            { label: '30% Growth in Site Users', icon: <SvgConnectUsers /> },
            { label: '212 Organic Keywords', icon: <SvgSpeech /> },
          ],
        },
      },
      {
        agent: 'The Noel Team',
        location: 'Los Angeles, CA',
        description:
          'Given Sherri Noel’s reputation as one of Los Angeles’ top luxury real estate agents, it’s unsurprising to see The Noel Team’s website exude a sense of elegance and style. The website has a modern, magazine-style layout, allowing it to effectively showcase the best of Los Angeles luxury real estate.',
        challenges: [
          'The Noel Team had two main goals in mind for its website: build up its reputation as a one-stop resource for potential buyers and sellers and use the site to dominate the online real estate market.',
        ],
        solutions: [
          'Agent Image first honed in on important niche keywords before giving the site’s location-specific “About Us” page a revamp. After that, Agent Image optimized the site’s dedicated community pages and listings and took advantage of online business directories.',
        ],
        results: [
          'Following the updates, TheNoelTeam.net reported an impressive 322% jump in new site users and improved brand reputation. Profiles and accounts on online directories and citations were also verified.',
        ],
        highlights: {
          items: [
            {
              label: '322% Growth in new Site Users',
              icon: <SvgConnectUsers />,
            },
            { label: 'improved brand reputation', icon: <SvgStarSpeech /> },
          ],
        },
      },
      {
        agent: 'Live The Life in Ecuador',
        location: 'Cuenca, Ecuador',
        description:
          'Torrin Brauch brings Ecuador within reach with LiveTheLifeInEcuador.com. To attract international home buyers, the welcome page features a slideshow of Ecuador’s captivating skylines, mountainscapes, and coasts, while large buttons linking to featured communities and beachfront properties line the bottom of the screen.',
        challenges: [
          'Ecuador has so much to offer, but except for travel sites, there are only a handful of real estate websites that are focused on what living here is like.',
          'There is a huge opportunity to leverage LiveTheLifeInEcuador.com’s online presence to host informative and clearly written content about the perks of buying a vacation home or investment property in Ecuador.',
        ],
        solutions: [
          'LiveTheLifeInEcuador.com came to life with an assortment of service packages from Agent Image.',
          'The site features nearly 80 pages of optimized relevant content and has been refined both on and off-page to ensure SEO relevance.',
        ],
        results: [
          'Torrin Brauch’s website enjoyed notable SEO growth between February 2015 to March 2017. The team now ranks on page 1 and 2 for crucial search terms such as “Cuenca Ecuador real estate”, “Cuenca Ecuador rentals, “Cuenca real estate”, “life in Ecuador”, and “Ecuador condos” and many others.',
        ],
        highlights: {
          items: [
            {
              label: 'leading the ranks<br/> for crucial search terms',
              icon: <SvgSpeech />,
            },
          ],
        },
      },
    ],
  },
}

export const closingData = {
  heading: {
    text: [
      'Are You Doing These Website-Boosting Strategies?',
      'Let’s Build You a Website That Builds Your Business!',
    ],
    className: 'subtitle-2',
  },
  paragraphs: {
    text: [
      'One of the first things you should do is make your website visible to search engines.<br/> As you can see above, SEO can help you achieve top ranking on search engines such as Google, which in turn leads to more clicks.',
      'If you need a website that generates leads, impresses visitors, and converts users, tap Agent Image’s expertise. With over 20 years of experience in web design and development, we can give you the online edge you need.',
    ],
    className: 'default-body',
  },
  checklist: [
    'Social media is a vital part of your marketing efforts, too. Posting listings and blog posts on your social media accounts will help drive organic traffic to your website. Make social media posts once a day and track which post types get the most engagement.',
    'If your website has images and videos, they should have keyword-rich descriptions as well. Remember: search engines look at all components of your site, including the visuals.',
    'Create content that educates buyers and sellers, including community guides, FAQs, and property market reports. Prospective clients crave information — and websites that provide it are generously rewarded by search engines.',
    'Blogs can be a great way for local real estate agents to show off their local knowledge. Make sure you provide real value and relevant content to keep readers coming back to your site.',
  ],
  buttonText: 'Learn more about our packages',
  contact: {
    text: 'For a FREE design consultation,<br/> just call',
    number: '1.877.677.1359',
  },
}
