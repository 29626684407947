import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import * as cx from './Closing.module.scss'

const Closing = ({
  wrapperClassName,
  heading1,
  heading2,
  paragraph1,
  paragraph2,
  buttonText,
  contactText,
  contactNumber,
  checklist,
  headingClassName = 'subtitle-2',
  paragraphClassName = 'default-body',
  contactClassname = 'subtitle-3',
  devices,
  bg,
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.container}>
        <div className={cx.devices}>
          <div className={cx.devicesPlaceholder}></div>
          <ElementImage src={devices} alt={`${heading1} devices`} />
        </div>

        <div className={cx.content}>
          <h4 className={headingClassName}>{heading1}</h4>
          <p className={paragraphClassName}>{parse(paragraph1)}</p>
          <ul className={cx.checklist}>
            {checklist?.map((text, key) => {
              return (
                <li key={key}>
                  <SvgCheckedCircle className={cx.checkSvg} />
                  <span className={paragraphClassName}>{text}</span>
                </li>
              )
            })}
          </ul>

          <h4 className={headingClassName}>{heading2}</h4>
          <p className={paragraphClassName}>{paragraph2}</p>
          <Button large text={buttonText} />
          <h5 className={contactClassname}>
            <span>{parse(contactText)}</span>
            <span>{contactNumber}</span>
          </h5>
        </div>
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  buttonText: PropTypes.string,
  contactText: PropTypes.string,
  contactNumber: PropTypes.string,
  checklist: PropTypes.array,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  devices: PropTypes.string,
  bg: PropTypes.string,
}

export default Closing
