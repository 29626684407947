import React from 'react'
import PropTypes from 'prop-types'

const SvgArrow = ({ strokeWidth = 2 }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      style={{ enableBackground: 'new 0 0 41 41' }}
      fill="none"
      className="stroke-current"
    >
      <title>Arrow</title>
      <path
        d="M32.4584 20.5L7.6875 20.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9116 31.3525C22.9116 26.7013 32.5587 20.4996 32.5587 20.4996C32.5587 20.4996 22.9116 14.2979 22.9116 9.64666"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SvgArrow.propTypes = {
  strokeWidth: PropTypes.number,
}

export default SvgArrow
