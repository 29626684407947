import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import useMedia, { media } from '@hooks/useMedia'
import { useIntersectOnMobile } from '@hooks/useIntersect'
import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import { Button } from '@components/global/buttons/Buttons'
import SvgArrow from '@svg/arrow.inline'
import SvgLocation from '@svg/location.inline'
import * as cx from './WebsiteList.module.scss'

const WebsiteItem = ({
  agent,
  location,
  description,
  challenges,
  solutions,
  results,
  highlights,
  highlightsText,
  buttonText,
  devicesImg,
  titleClassName = 'heading-3',
  subtitleClassName = 'subtitle-7',
  paragraphClassName = 'default-body large',
  labelClassName = 'small-heading strong',
  ctaOnClick,
}) => {
  const [
    highlightRef,
    isHighlightIntersecting,
    isHighlightMobile,
  ] = useIntersectOnMobile({
    root: null,
    threshold: 1,
  })

  return (
    <li className={cx.itemWrapper}>
      <div className={cx.bgGradientA} />
      <div className={cx.bgGradientB} />

      <div className={cx.item}>
        <div className={cx.devices}>
          <ElementImage
            src={devicesImg}
            alt={`${agent} devices`}
            width={619}
            height={1036}
          />
        </div>

        <div className={cx.content}>
          <div className={cx.contentTop}>
            <h4 className={titleClassName}>{agent}</h4>
            <div className={cx.location}>
              <SvgLocation />
              <span className={subtitleClassName}>{location}</span>
            </div>

            <p className={paragraphClassName}>{description}</p>
          </div>

          <div className={cx.contentMid}>
            <div>
              <span className={subtitleClassName}>The Challenge</span>
              <hr />
              {challenges?.map((text, key) => {
                return (
                  <p key={key} className={paragraphClassName}>
                    {text}
                  </p>
                )
              })}
            </div>
            <div className={cx.solution}>
              <span className={subtitleClassName}>The Solution</span>
              <hr />
              {solutions?.map((text, key) => {
                return (
                  <p key={key} className={paragraphClassName}>
                    {text}
                  </p>
                )
              })}
            </div>
          </div>

          <div className={cx.contentBottom}>
            <span className={subtitleClassName}>Key Highlights & Results</span>
            <hr />
            <div
              ref={highlightRef}
              className={clsx([cx.highlights], {
                [cx.highlightIntersect]:
                  isHighlightMobile && isHighlightIntersecting,
                'tempo-hidden': isHighlightMobile && !isHighlightIntersecting,
              })}
            >
              {highlights?.map((highlight, key) => {
                return (
                  <div key={key} className={cx.highlight}>
                    <span>{highlight.icon}</span>
                    <span className={labelClassName}>
                      {parse(highlight.label)}
                    </span>
                  </div>
                )
              })}
            </div>
            {highlightsText && (
              <span className={labelClassName}>{highlightsText}</span>
            )}

            {results?.map((text, key) => {
              return (
                <p key={key} className={paragraphClassName}>
                  {text}
                </p>
              )
            })}
          </div>

          <Button large text={buttonText} onClick={ctaOnClick} />
        </div>
      </div>
    </li>
  )
}

const WebsiteList = ({
  wrapperClassName,
  heading,
  subheading,
  paragraph1,
  paragraph2,
  buttonText,
  headingClassName = 'heading-5',
  headingPhoneClassName = 'heading-3',
  subheadingClassName = 'subtitle-1',
  paragraphClassName = 'default-body large',
  item: websiteItem,
}) => {
  const isPhone = useMedia(media.phone)
  const modalRef = useRef()

  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <ModalForm
        childFunc={modalRef}
        desktopTitle="Sign Up Today For A <br>Free Consultation"
        mobileTitle="Sign Up Today For A <br>Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m2"
        type="a"
      >
        <FormSignUp
          submitLabel="Get Started Today"
          labelClassName="desktop-only"
          messageLabel="Questions or Comments"
        />
      </ModalForm>

      <div className={cx.header}>
        <div className={cx.headerContainer}>
          <h3>
            <span className={subheadingClassName}>{subheading}</span>
            <span
              className={isPhone ? headingPhoneClassName : headingClassName}
            >
              {heading}
            </span>
          </h3>
          <hr />
          <p className={paragraphClassName}>{paragraph1}</p>
          <p className={paragraphClassName}>{paragraph2}</p>
          <span className={cx.svgArrow}>
            <SvgArrow strokeWidth={6} />
          </span>
        </div>
      </div>

      <ul className={cx.list} id="websitelist-marketing-websites">
        {websiteItem?.map((item, key) => {
          return (
            <WebsiteItem
              key={key}
              agent={item?.agent}
              location={item?.location}
              description={item?.description}
              challenges={item?.challenges}
              solutions={item?.solutions}
              results={item?.results}
              highlights={item?.highlights.items}
              highlightsText={item?.highlights?.text}
              buttonText={buttonText}
              devicesImg={item?.img}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ul>
    </section>
  )
}

WebsiteItem.propTypes = {
  agent: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  challenges: PropTypes.array,
  solutions: PropTypes.array,
  results: PropTypes.array,
  highlights: PropTypes.array,
  highlightsText: PropTypes.string,
  buttonText: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  ctaOnClick: PropTypes.func,
}

WebsiteList.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  buttonText: PropTypes.string,
  headingClassName: PropTypes.string,
  headingPhoneClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  item: PropTypes.array,
}

export default WebsiteList
