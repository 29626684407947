import React from 'react'

const SvgSpeech = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="103"
      height="102"
      viewBox="0 0 103 102"
      fill="none"
    >
      <path
        d="M97.2779 57.6758H97.2491C89.3798 57.7044 81.6546 57.7044 75.803 57.7044C72.4017 57.7044 69.0291 57.7044 65.6277 57.7044C62.2263 57.7044 58.8538 57.7044 55.4524 57.7044H53.7805C51.215 57.7044 50.0332 58.9078 50.0332 61.4865C50.0332 63.8932 50.0332 66.2713 50.0332 68.678C50.0332 71.2853 51.215 72.46 53.867 72.46H66.5789C71.854 72.46 88.1691 72.46 94.5395 72.46C95.0872 72.46 95.6349 72.4887 96.1826 72.4887C97.0761 72.4887 97.8256 72.46 98.5174 72.4027C99.0939 72.3454 99.8145 72.0303 100.333 71.6005C100.622 71.3713 100.852 70.9415 101.112 70.5117C101.198 70.3685 101.371 60.1971 101.371 60.1971C100.766 58.3921 99.5551 57.6758 97.2779 57.6758ZM100.16 68.8499C100.16 70.2825 99.7569 71.1994 97.768 71.1994C97.4797 71.1994 97.1626 71.1994 96.8744 71.1994H95.7502C95.5772 71.1994 95.3755 71.1994 95.2025 71.1994C87.5638 71.1994 71.9405 71.1994 65.4836 71.1994H53.9534C51.878 71.1994 51.2727 70.5977 51.2727 68.5061V67.1022C51.2727 65.1825 51.2727 63.2342 51.2727 61.3145C51.2727 59.6527 51.9933 58.9365 53.6652 58.9365C60.6986 58.9365 67.732 58.9365 74.7653 58.9365C82.4329 58.9365 90.1292 58.9365 97.7968 58.9365C99.4398 58.9365 100.132 59.6241 100.132 61.2572C100.189 63.6353 100.189 66.128 100.16 68.8499Z"
        fill="black"
      />
      <path
        d="M29.2191 46.5012H30.9487C32.4476 46.5012 33.9753 46.5012 35.4742 46.5012C38.1262 46.5012 40.2881 46.5011 42.3058 46.4725H42.3347C43.2571 46.4725 43.9489 46.759 44.583 47.418C46.0243 48.9079 47.5232 50.3691 48.9933 51.8017L49.1951 52.0023C49.6563 52.4607 50.0599 52.6899 50.3769 52.6899C50.7228 52.6899 51.1264 52.4607 51.5876 52.0023C52.1641 51.4579 52.7118 50.8849 53.2595 50.3405C54.2395 49.3377 55.2772 48.3349 56.315 47.3321C56.8338 46.845 57.6409 46.5012 58.3616 46.5012C61.3306 46.4725 64.2707 46.4725 66.9803 46.4725H73.8408C77.4439 46.4725 79.577 44.3236 79.577 40.7422C79.577 32.0607 79.577 23.4079 79.577 14.7264C79.577 11.145 77.4151 8.99609 73.8408 8.99609C66.5768 8.99609 59.3128 8.99609 52.0488 8.99609H29.3344C25.616 8.99609 23.5694 11.0304 23.5694 14.7551C23.5694 17.0186 23.5694 19.282 23.5694 21.5455C23.5694 26.8747 23.5694 39.7107 23.5694 40.8281C23.5406 44.3809 25.6736 46.5012 29.2191 46.5012ZM24.7512 14.7837C24.7512 11.7753 26.279 10.2568 29.3056 10.2568H73.7255C76.7521 10.2568 78.2799 11.7753 78.2799 14.7837C78.2799 23.4365 78.2799 32.0607 78.2799 40.7135C78.2799 43.6933 76.6945 45.2691 73.7255 45.2691C73.7255 45.2691 67.3262 45.2691 65.6544 45.2691C62.9448 45.2691 60.2352 45.2691 57.5256 45.2405C56.8338 45.2405 56.3438 45.441 55.8826 45.9281C54.7584 47.0742 53.6342 48.2202 52.4235 49.395C51.9335 49.882 51.4435 50.3691 50.9534 50.8562L50.3481 51.4579L49.7428 50.8562C49.2527 50.3691 48.7627 49.882 48.3015 49.4236C47.1197 48.2489 46.0243 47.1601 44.9289 46.0141C44.3813 45.4697 43.8336 45.2405 43.0553 45.2405H43.0265C41.0087 45.2691 38.8468 45.2691 36.2525 45.2691C34.4654 45.2691 32.6782 45.2691 30.9198 45.2691H29.1615C26.4231 45.2691 24.8377 43.6933 24.8089 40.9427C24.8089 40.2837 24.8089 39.6534 24.8089 38.9944C24.8089 38.3927 24.8089 27.0467 24.8089 21.8607C24.7512 19.5113 24.7512 17.1332 24.7512 14.7837Z"
        fill="black"
      />
      <path
        d="M54.0679 78.2188C50.8106 78.2188 19.391 78.2188 19.391 78.2188C16.2202 78.2188 13.0206 78.2188 9.84982 78.2188C7.65909 78.2188 6.39078 79.4794 6.36195 81.657C6.33313 84.207 6.33313 86.9002 6.36195 89.6221C6.39078 91.5991 7.63027 92.8598 9.61921 92.9171C10.2534 92.9457 10.9452 92.9457 11.7523 92.9457C12.4153 92.9457 23.3112 92.9457 28.4998 92.9171C32.6507 92.9171 37.4933 92.9171 39.367 92.9171C44.3249 92.9171 49.2829 92.9171 54.2408 92.9171C56.4892 92.9171 57.7575 91.6564 57.7575 89.3929C57.7575 86.7856 57.7575 84.207 57.7575 81.8002C57.7575 79.3935 56.5469 78.2188 54.0679 78.2188ZM56.518 88.9058C56.518 91.198 55.9704 91.7423 53.6643 91.7423C53.6643 91.7423 40.1741 91.7423 30.3735 91.7423C30.2293 91.7423 30.0564 91.7423 29.9122 91.7423H11.2046C10.7722 91.7423 10.3687 91.7423 9.93629 91.7423C8.32207 91.7137 7.63027 91.0261 7.63027 89.3929C7.63027 87.1867 7.63027 84.8086 7.63027 81.8862C7.63027 80.1384 8.32207 79.4794 10.0804 79.4794H32.103C39.4534 79.4794 46.7751 79.4794 54.1255 79.4794C55.8551 79.4794 56.5469 80.1671 56.5469 81.9148C56.5469 83.6339 56.5469 85.3817 56.5469 87.1008L56.518 88.9058Z"
        fill="black"
      />
      <path
        d="M22.8214 72.4594H33.1121C35.6199 72.4594 38.1565 72.4594 40.6643 72.4594C42.855 72.4594 44.1233 71.2847 44.1522 69.2504C44.2098 66.4139 44.2098 63.606 44.1522 60.9128C44.1233 58.9644 42.8262 57.7324 40.8084 57.7324C34.8416 57.7324 28.8459 57.7324 22.8791 57.7324C16.9122 57.7324 10.9166 57.7324 4.94971 57.7324C2.87429 57.7324 1.60597 59.0217 1.57715 61.0847C1.57715 62.9184 1.57715 64.7521 1.57715 66.5571V68.6487C1.57715 71.3133 2.70134 72.4594 5.38209 72.4594C7.74577 72.4594 10.1094 72.4594 12.502 72.4594H22.8214ZM2.84546 68.6201V67.5886C2.84546 65.497 2.84546 63.4055 2.84546 61.3425C2.84546 59.6521 3.53727 58.9644 5.23797 58.9644C11.2625 58.9644 17.287 58.9644 23.2826 58.9644C29.0189 58.9644 34.7839 58.9644 40.5202 58.9644C41.9903 58.9644 42.9127 59.3656 42.9415 61.3139C42.9415 63.5774 42.9415 66.0414 42.9415 68.8206C42.9415 70.597 42.3073 71.2274 40.4913 71.2274C37.6953 71.2274 34.8992 71.2274 32.1032 71.2274H22.8502H5.49739C3.42197 71.2274 2.84546 70.6257 2.84546 68.6201Z"
        fill="black"
      />
      <path
        d="M93.3862 78.2188C91.5702 78.2188 89.7542 78.2188 87.967 78.2188H72.3437C70.4989 78.2188 68.654 78.2188 66.8092 78.2188C64.7626 78.2188 63.6673 79.3075 63.6384 81.3131C63.6384 83.8918 63.6384 86.6423 63.6384 89.794C63.6384 91.8569 64.7338 92.9457 66.7516 92.9457C71.2195 92.9457 75.7162 92.9744 80.1842 92.9744C84.6233 92.9744 89.0912 92.9744 93.5303 92.9457C95.5481 92.9457 96.6723 91.8283 96.6723 89.794C96.6723 87.3013 96.6723 84.5508 96.6723 81.4277C96.6434 79.3075 95.5481 78.2188 93.3862 78.2188ZM95.4039 89.5362C95.4039 91.1693 94.8563 91.7423 93.242 91.7423C89.9848 91.7423 86.7275 91.7423 83.4991 91.7423C77.9934 91.7423 72.4878 91.7423 66.9822 91.7423C66.521 91.7423 65.858 91.685 65.3968 91.198C65.0509 90.8541 64.8779 90.3384 64.8779 89.6508C64.9067 87.0435 64.9067 84.2929 64.8779 81.485C64.8779 80.7974 65.022 80.3103 65.3968 79.9665C65.858 79.4794 66.521 79.4221 66.9533 79.4221H66.9822C68.7982 79.4221 70.7295 79.4508 73.2373 79.4508C74.3614 79.4508 78.9159 79.4508 80.0689 79.4508C80.0689 79.4508 91.2243 79.4508 93.1556 79.4508C94.9139 79.4508 95.4039 79.9378 95.4039 81.7429C95.4039 84.3216 95.4039 86.9289 95.4039 89.5362Z"
        fill="black"
      />
      <path
        d="M40.6041 32.233C40.8347 32.233 41.123 32.061 41.4112 31.7745C42.103 31.0869 42.7948 30.3706 43.4866 29.683C43.8037 29.3678 44.092 29.0526 44.409 28.7375L44.9567 28.1644L45.5621 28.6801C45.6485 28.7375 45.7062 28.7948 45.7927 28.8521C45.9368 28.9667 46.0521 29.0526 46.1674 29.1672C46.5133 29.4824 46.8304 29.8262 47.1474 30.1414C47.7528 30.7431 48.3869 31.3734 49.0211 31.9751C49.1652 32.1184 49.367 32.2043 49.5399 32.2043C49.6264 32.2043 49.7705 32.1757 49.8858 32.061C50.1453 31.8032 50.2318 31.6026 49.9435 31.1728C49.9147 31.1155 49.857 31.0296 49.7994 30.9723C49.7706 30.9436 49.7417 30.8863 49.7129 30.8577L49.2229 30.256L54.6709 24.8695L55.2762 25.4425L55.478 25.6431C55.6509 25.815 55.8527 25.9869 56.0256 26.1588C57.2363 27.3622 58.4758 28.5942 59.6865 29.7976L61.3583 31.4594C61.5025 31.6026 61.6178 31.7172 61.7619 31.8605C61.9925 32.0897 62.2519 32.2043 62.4537 32.2043C62.5978 32.2043 62.7131 32.147 62.8284 32.0324C63.232 31.6313 62.8284 31.1728 62.6843 31.0296C60.2341 28.5655 57.784 26.1301 55.3915 23.7807C54.9303 23.3223 54.6997 23.2363 54.5844 23.2363C54.4979 23.2363 54.2385 23.3223 53.7484 23.8094C52.6243 24.9268 51.4712 26.0728 50.2894 27.2476L48.0122 29.511L47.4069 28.8234C47.2051 28.5942 47.0321 28.3936 46.8304 28.1931C46.4556 27.7633 46.1097 27.3908 45.7638 27.0184C45.3603 26.6172 45.1585 26.5599 45.072 26.5599C44.9855 26.5599 44.7549 26.6172 44.3514 27.0184C43.1695 28.1931 41.9012 29.4251 40.4888 30.829C40.2005 31.1155 40.0276 31.4307 40.0276 31.6313C40.0276 31.7172 40.0564 31.8318 40.2294 31.9751C40.4023 32.2043 40.5176 32.233 40.6041 32.233Z"
        fill="#009BBB"
      />
      <path
        d="M80.6127 64.4668C76.779 64.4668 72.974 64.4668 69.1403 64.4668C65.3641 64.4668 61.6168 64.4668 57.8407 64.4668C56.7454 64.4668 56.7454 64.9252 56.7454 65.0971C56.7454 65.2977 56.803 65.4123 56.8607 65.4696C57.0336 65.6415 57.3507 65.7275 57.8119 65.7275C59.4838 65.7275 61.1556 65.7275 62.7987 65.7275H69.1979H75.7701C77.4131 65.7275 79.0562 65.7275 80.6704 65.7275C81.0451 65.7275 81.3334 65.6415 81.4775 65.5269C81.5063 65.4983 81.5928 65.4123 81.5928 65.1258C81.6216 64.8679 81.5928 64.4668 80.6127 64.4668Z"
        fill="#009BBB"
      />
      <path
        d="M22.7642 84.9531C21.2941 84.9531 19.824 84.9531 18.3539 84.9531C16.8838 84.9531 15.4425 84.9531 13.9724 84.9531C13.6553 84.9531 13.3959 85.0391 13.2518 85.1823C13.1653 85.2683 13.1077 85.4116 13.1077 85.5835C13.1077 85.9846 13.3959 86.1851 14.0013 86.1851C14.5778 86.1851 15.1543 86.1851 15.7308 86.1851H18.3539H21.0346C21.64 86.1851 22.2165 86.1851 22.8218 86.1851C23.1389 86.1851 23.4271 86.0992 23.5424 85.9559C23.6289 85.87 23.6866 85.7267 23.6866 85.5835C23.6577 85.1823 23.3695 84.9531 22.7642 84.9531Z"
        fill="#009BBB"
      />
      <path
        d="M34.6369 84.9818C33.6569 84.9531 32.648 84.9531 31.6391 84.9531C30.659 84.9531 29.6501 84.9531 28.6413 84.9818C28.4395 84.9818 28.2665 85.0391 28.1512 85.1823C28.0359 85.2969 27.9783 85.4402 27.9783 85.6121C28.0071 86.1278 28.5548 86.1851 28.7277 86.1851C29.1025 86.1851 29.4772 86.1851 29.8231 86.1851C30.1113 86.1851 30.3996 86.1851 30.6879 86.1851C30.9761 86.1851 31.2644 86.1851 31.5814 86.1851C31.8697 86.1851 32.1868 86.1851 32.475 86.1851C32.7633 86.1851 33.0515 86.1851 33.3686 86.1851C33.7433 86.1851 34.1181 86.1851 34.5216 86.1851C35.2423 86.1565 35.2711 85.7554 35.2711 85.6121C35.2711 85.4402 35.2134 85.2683 35.127 85.1537C34.9828 85.0391 34.8387 84.9818 34.6369 84.9818Z"
        fill="#009BBB"
      />
      <path
        d="M23.4883 65.0971C23.4883 64.8106 23.4018 64.7247 23.3442 64.696C23.2001 64.5528 22.9118 64.4668 22.5371 64.4668C20.3464 64.4668 18.1268 64.4668 15.9361 64.4668C13.7165 64.4668 11.497 64.4668 9.27742 64.4668C8.32619 64.4668 8.29736 64.8679 8.29736 65.0971C8.29736 65.355 8.38384 65.4696 8.41266 65.4983C8.55679 65.6415 8.84504 65.7275 9.2486 65.7275C10.1422 65.7275 11.0358 65.7275 11.9294 65.7275H15.7055H19.741C20.6634 65.7275 21.5858 65.7275 22.5083 65.7275C23.4883 65.6988 23.4883 65.3263 23.4883 65.0971Z"
        fill="#009BBB"
      />
      <path
        d="M77.8454 84.9525C76.7212 84.9238 75.5682 84.9238 74.444 84.9238C73.3198 84.9238 72.1668 84.9238 71.0426 84.9525C70.8697 84.9525 70.6391 84.9811 70.495 85.153C70.3797 85.2963 70.3508 85.4682 70.3508 85.6115C70.3508 85.812 70.3797 86.1558 71.1003 86.1845C71.5327 86.1845 71.9651 86.1845 72.3974 86.1845C72.7433 86.1845 73.0892 86.1845 73.4063 86.1845C73.7522 86.1845 74.0981 86.1845 74.4729 86.1845C74.8188 86.1845 75.1358 86.1845 75.4817 86.1845C75.8276 86.1845 76.1447 86.1845 76.4906 86.1845C76.923 86.1845 77.3554 86.1845 77.8166 86.1845C78.1625 86.1845 78.566 86.0699 78.566 85.6115C78.566 85.4396 78.5372 85.2963 78.4219 85.1817C78.2778 85.0384 78.076 84.9811 77.8454 84.9525Z"
        fill="#009BBB"
      />
    </svg>
  )
}

export default SvgSpeech
