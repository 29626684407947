// extracted by mini-css-extract-plugin
export var bgGradientA = "WebsiteList-module--bg-gradient-a--25bd3";
export var bgGradientB = "WebsiteList-module--bg-gradient-b--3127f";
export var content = "WebsiteList-module--content--75e7a";
export var contentBottom = "WebsiteList-module--content-bottom--71cb5";
export var contentMid = "WebsiteList-module--content-mid--24195";
export var contentTop = "WebsiteList-module--content-top--5b39c";
export var devices = "WebsiteList-module--devices--c8d47";
export var header = "WebsiteList-module--header--cc27b";
export var headerContainer = "WebsiteList-module--header-container--9f1fb";
export var highlight = "WebsiteList-module--highlight--7efe8";
export var highlightIntersect = "WebsiteList-module--highlight-intersect--92521";
export var highlights = "WebsiteList-module--highlights--5ee61";
export var item = "WebsiteList-module--item--fc306";
export var itemWrapper = "WebsiteList-module--item-wrapper--cb81f";
export var list = "WebsiteList-module--list--a91b4";
export var location = "WebsiteList-module--location--c5587";
export var solution = "WebsiteList-module--solution--09e7b";
export var svgArrow = "WebsiteList-module--svg-arrow--d49d3";
export var wrapper = "WebsiteList-module--wrapper--3e69e";