import { useStaticQuery, graphql } from 'gatsby'

const useBest10MarketingMetadata = () => {
  const query = useStaticQuery(graphql`
    query best10MarketingMetadata {
      bannerBG: file(
        relativePath: { eq: "best-10-marketing/banner/banner-bg-3.png" }
      ) {
        id
        ...Best10MarketingImageOptimize
      }

      introDevices: file(
        relativePath: { eq: "best-10-marketing/intro/intro-devices-3.png" }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      introAccent: file(
        relativePath: { eq: "best-10-marketing/intro/intro-accent.png" }
      ) {
        id
        ...Best10MarketingImageOptimize
      }

      websiteListNatalie: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-natalie.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      websiteListOppenheim: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-oppenheim.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      websiteListChad: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-chad.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      websiteListValery: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-valery.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      websiteListNoel: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-noel.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      websiteListEcuador: file(
        relativePath: {
          eq: "best-10-marketing/websitelist/websitelist-ecuador.png"
        }
      ) {
        id
        ...Best10MarketingImageOptimize
      }

      closingBG: file(
        relativePath: { eq: "best-10-marketing/closing/closing-bg.jpg" }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
      closingDevices: file(
        relativePath: { eq: "best-10-marketing/closing/closing-devices.png" }
      ) {
        id
        ...Best10MarketingImageOptimize
      }
    }

    fragment Best10MarketingImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest10MarketingMetadata
