import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import Banner from '@components/global/banner/Banner'
import ElementImage from '@components/global/element/Image'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  Intro,
  WebsiteList,
  Closing,
} from '@components/pages/best-10-marketing'

import {
  pageData as page,
  bannerData,
  introData,
  websiteListData,
  closingData,
} from '@src/data/Best10Marketing'
import useBest10MarketingMetadata from '@hooks/best-10-marketing'
import useMedia, { media } from '@hooks/useMedia'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Best10RealEstateMarketingWebsites.module.scss'

const Best10RealEstateMarketingWebsites = () => {
  const {
    bannerBG,
    introDevices,
    introAccent,
    websiteListNatalie,
    websiteListOppenheim,
    websiteListChad,
    websiteListValery,
    websiteListNoel,
    websiteListEcuador,
    closingBG,
    closingDevices,
  } = useBest10MarketingMetadata()
  const websiteItemData = [
    {
      agent: websiteListData.websiteList?.websiteItem[0]?.agent,
      location: websiteListData.websiteList?.websiteItem[0]?.location,
      description: websiteListData.websiteList?.websiteItem[0]?.description,
      challenges: websiteListData.websiteList?.websiteItem[0]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[0]?.solutions,
      results: websiteListData.websiteList?.websiteItem[0]?.results,
      highlights: websiteListData.websiteList?.websiteItem[0]?.highlights,
      img: extractImage(websiteListNatalie),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[1]?.agent,
      location: websiteListData.websiteList?.websiteItem[1]?.location,
      description: websiteListData.websiteList?.websiteItem[1]?.description,
      challenges: websiteListData.websiteList?.websiteItem[1]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[1]?.solutions,
      results: websiteListData.websiteList?.websiteItem[1]?.results,
      highlights: websiteListData.websiteList?.websiteItem[1]?.highlights,
      img: extractImage(websiteListOppenheim),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[2]?.agent,
      location: websiteListData.websiteList?.websiteItem[2]?.location,
      description: websiteListData.websiteList?.websiteItem[2]?.description,
      challenges: websiteListData.websiteList?.websiteItem[2]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[2]?.solutions,
      results: websiteListData.websiteList?.websiteItem[2]?.results,
      highlights: websiteListData.websiteList?.websiteItem[2]?.highlights,
      img: extractImage(websiteListChad),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[3]?.agent,
      location: websiteListData.websiteList?.websiteItem[3]?.location,
      description: websiteListData.websiteList?.websiteItem[3]?.description,
      challenges: websiteListData.websiteList?.websiteItem[3]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[3]?.solutions,
      results: websiteListData.websiteList?.websiteItem[3]?.results,
      highlights: websiteListData.websiteList?.websiteItem[3]?.highlights,
      img: extractImage(websiteListValery),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[4]?.agent,
      location: websiteListData.websiteList?.websiteItem[4]?.location,
      description: websiteListData.websiteList?.websiteItem[4]?.description,
      challenges: websiteListData.websiteList?.websiteItem[4]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[4]?.solutions,
      results: websiteListData.websiteList?.websiteItem[4]?.results,
      highlights: websiteListData.websiteList?.websiteItem[4]?.highlights,
      img: extractImage(websiteListNoel),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[5]?.agent,
      location: websiteListData.websiteList?.websiteItem[5]?.location,
      description: websiteListData.websiteList?.websiteItem[5]?.description,
      challenges: websiteListData.websiteList?.websiteItem[5]?.challenges,
      solutions: websiteListData.websiteList?.websiteItem[5]?.solutions,
      results: websiteListData.websiteList?.websiteItem[5]?.results,
      highlights: websiteListData.websiteList?.websiteItem[5]?.highlights,
      img: extractImage(websiteListEcuador),
    },
  ]

  const isTablet = useMedia(media.tablet)

  return (
    <LayoutInnerPage
      wrapperClassName={cx.wrapper}
      buttonsHeight={page.buttonsHeight}
    >
      <Seo title={page.title} uri={page.uri} />
      <Banner
        wrapperClassName={cx.banner}
        layout={bannerData.layout.container}
        formLayout={bannerData.form.layout}
        breadcrumbs={page.breadcrumbs}
        title={bannerData.heading.text}
        subtitle2={bannerData.subheading.text}
        titleClassName={
          isTablet
            ? bannerData.heading.tabletClassName
            : bannerData.heading.className
        }
        subtitle2ClassName={bannerData.subheading.className}
        formHeadingClassName={bannerData.form.subheadingClassName}
        buttonClassName={
          isTablet
            ? bannerData.form.buttonTabletClassName
            : bannerData.form.buttonClassName
        }
        devices={{
          layout: bannerData.layout.devices,
        }}
      >
        <div className={cx.bannerImgPlaceholder}>
          <ElementImage
            src={extractImage(bannerBG)}
            width={bannerData.bg.width}
            height={bannerData.bg.height}
            alt={bannerData.imgAlt}
            lazy={false}
          />
        </div>
      </Banner>
      <Intro
        heading={introData.heading.text}
        paragraphs={introData.paragraphs.text}
        buttonText={introData.button.text}
        buttonScrollTo={introData.button.scrollTo}
        accent={extractImage(introAccent)}
        devices={extractImage(introDevices)}
      />
      <WebsiteList
        wrapperClassName={cx.websiteList}
        heading={websiteListData.heading.text}
        subheading={websiteListData.subheading.text}
        paragraph1={websiteListData.paragraph.text1}
        paragraph2={websiteListData.paragraph.text2}
        buttonText={websiteListData.buttonText}
        item={websiteItemData}
      />
      <Closing
        heading1={closingData.heading.text[0]}
        heading2={closingData.heading.text[1]}
        paragraph1={closingData.paragraphs.text[0]}
        paragraph2={closingData.paragraphs.text[1]}
        checklist={closingData.checklist}
        buttonText={closingData.buttonText}
        contactText={closingData.contact.text}
        contactNumber={closingData.contact.number}
        devices={extractImage(closingDevices)}
        bg={extractImage(closingBG)}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.modelOrBackground}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10RealEstateMarketingWebsites
