import React from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Intro.module.scss'

const Intro = ({
  wrapperClassName,
  heading,
  paragraphs,
  buttonText,
  buttonScrollTo,
  headingClassName = 'subtitle-2',
  paragraphClassName = 'default-body large',
  devices,
  accent,
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.outerContainer}>
        <div className={cx.accent}>
          <ElementImage src={accent} alt={`${heading} background`} />
        </div>

        <div className={cx.container}>
          <div className={cx.content}>
            <h3 className={headingClassName}>{heading}</h3>
            {paragraphs?.map((text, key) => {
              return (
                <p key={key} className={paragraphClassName}>
                  {text}
                </p>
              )
            })}
            <Button
              large
              text={buttonText}
              onClick={() => scrollTo(buttonScrollTo)}
            />
          </div>
          <div className={cx.devices}>
            <div className={cx.devicesPlaceholder}></div>
            <ElementImage src={devices} alt={`${heading} devices`} />
          </div>
        </div>
      </div>
    </section>
  )
}

Intro.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  paragraphs: PropTypes.array,
  buttonText: PropTypes.string,
  buttonScrollTo: PropTypes.string,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  devices: PropTypes.string,
  accent: PropTypes.string,
}

export default Intro
